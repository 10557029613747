<template>
  <div class="container-fluid p-0 m-0">
    <div
      id="login-wrapper"
      class="d-md-flex justify-content-center login-wrapper vh-100"
    >
      <div class="login-form d-flex align-items-center px-5">
        <div class="login-form--block mx-auto">
          <b-img
            src="/media/images/drsg-login-logo.svg"
            fluid
            alt="Responsive image"
          />
          <b-form @submit.prevent="onSubmit" class="g-3 needs-validation">
            <b-form-group
              id="input-group-1"
              label="Username"
              label-for="username"
            >
              <b-form-input
                id="username"
                v-model="form.username"
                placeholder="Enter username"
                :class="{ 'is-invalid': $v.form.username.$error }"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-2"
              label="Password:"
              label-for="password"
            >
              <b-form-input
                id="password"
                type="password"
                v-model="form.password"
                placeholder="Enter password"
                :class="{ 'is-invalid': $v.form.password.$error }"
              ></b-form-input>
            </b-form-group>
            <div
              class="form-group d-lg-flex align-items-center"
              role="group"
              :class="{ 'form-group--error': isFailed }"
            >
              <b-button
                id="login-btn"
                type="submit"
                variant="brand-primary"
                :disabled="showLoader"
                :class="{ 'is-invalid': isFailed }"
              >
                <b-spinner small v-if="showLoader"></b-spinner>
                Login
              </b-button>
              <b-form-invalid-feedback
                id="login-failed"
                class="mt-3 mt-lg-1 ml-lg-2 invalid-feedback"
              >
                <!-- {{ sytemMessages.loginFailed }} -->
                {{ loginFailedMsg }}
              </b-form-invalid-feedback>
            </div>
          </b-form>
          <hr />
          <div class="login-info">
            <h5 class="title">New to DRSG?</h5>
            <p class="description">
              Please contact to us to register and start set up your pricing.
              <a href="mailto:miwalker@dufresne.ca">Contact Us</a>
            </p>
          </div>
        </div>
      </div>
      <div class="login-image d-none d-lg-block d-xl-block">
        <b-img
          src="/media/images/login-image.png"
          class="w-100 h-100"
          alt="Responsive image"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import moment from "moment";
import { required } from "vuelidate/lib/validators";
import { forEach } from "lodash";

export default {
  name: "Login",
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Login",
  },
  data() {
    return {
      showLoader: false,
      isFailed: false,
      loginFailedMsg: "",
      form: {
        username: "",
        password: "",
      },
    };
  },
  methods: {
    ...mapActions("auth", ["LogIn"]),
    ...mapActions([
      "getStatusCode",
      "getModuleMasterList",
      "getUserModulerPermission",
      "getLanguages",
      "getTranslations",
      "getVersionHistory",
    ]),
    ...mapMutations("auth", {
      setItem: "setItem",
      removeSavedCredentials: "LogOut",
    }),
    ...mapMutations({
      generalSetItem: "setItem",
    }),
    async onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        return;
      }

      this.isFailed = false;

      this.showLoader = true;

      let response = await this.LogIn(this.form);
      if (response) {
        if (response.status == 0) {
          this.showLoader = false;
          this.isFailed = true;
          this.loginFailedMsg = response.message;
          return;
        }

        localStorage.setItem("lastLoginAt", moment());
        this.getStatusCode();

        const languages = await this.getLanguages();
        // await this.getTranslations({languages: languages});

        const default_en = languages.find((item) => item.languageCode == "en");

        let languageId = default_en.languageId;
        this.getVersionHistory(languageId);

        await this.getTranslations({ langId: default_en.languageId });

        let modulePermissionListResponse = await this.getModuleMasterList();
        let userModulerPermissionResponse =
          await this.getUserModulerPermission();

        let modulePermissions = {};

        forEach(modulePermissionListResponse, (module) => {
          let moduleName = module.module.replace(/\s+/g, "-").toLowerCase();
          //applied all the permission for admin user
          if (this.user.userType == this.adminUser) {
            modulePermissions[moduleName] = true;
          } else {
            //applied condition based the permission for non admin user
            modulePermissions[moduleName] = false;
            let existPermissionModule = userModulerPermissionResponse.find(
              (permission) => permission.moduleId == module.id
            );
            if (existPermissionModule) {
              modulePermissions[moduleName] =
                existPermissionModule.isAccess == 1 ? true : false;
            }
          }
        });

        this.setItem({
          resource: "userModulerPermission",
          payload: modulePermissions,
        });

        let routePath = "/";

        this.setItem({
          resource: "defaultLandingRoute",
          payload: routePath,
        });

        this.$router.push(routePath);
      } else {
        this.isFailed = true;
      }
      this.showLoader = false;
    },
  },
  computed: {
    ...mapGetters("auth", ["user", "siteAccess"]),
  },
  watch: {
    siteAccess(newVal) {
      if (newVal.length) {
        this.generalSetItem({
          resource: "userDashboardSite",
          payload: newVal[0],
        });
      }
    },
    form() {
      this.isFailed = false;
      this.loginFailedMsg = "";
    },
  },
  validations: {
    form: {
      username: {
        required,
      },
      password: {
        required,
      },
    },
  },
  beforeMount() {
    this.generalSetItem({ resource: "isLoading", payload: false });
    this.generalSetItem({ resource: "userDashboardSite", payload: {} });
    this.removeSavedCredentials();
  },
};
</script>
